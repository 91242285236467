import { createContext, useContext, useMemo, useState } from "react";

export const AppStateContext = createContext({
  state: {
    hasHistory: false,
    loading: true,
  },
  upvotes: {},
  setState: () => {},
  setUpvotes: () => {},
});

export const useAppState = () => useContext(AppStateContext);

export const AppStateProvider = ({ children }) => {
  const [appState, setState] = useState({
    loading: true,
  });
  const [upvotes, setUpvotes] = useState({});
  
  const state = useMemo(() => ({
    state: appState,
    setState,
    setUpvotes,
    upvotes,
  }), [appState, setUpvotes]);

  return (
    <AppStateContext.Provider value={state}>
      {children}
    </AppStateContext.Provider>
  );
};

export const withAppState = (Component) => (props) => (
  <AppStateProvider>
    <Component {...props} />
  </AppStateProvider>
);
