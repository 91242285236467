import { SnackbarProvider } from "notistack";

const withToasts = (Component) => (props) => (
  <SnackbarProvider
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
  >
    <Component {...props} />
  </SnackbarProvider>
);

export default withToasts;
