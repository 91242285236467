import { useSession } from "store/Session";
import hit from "hit";

const useHit = () => {
  const { data } = useSession();
  return (endpoint, body, headers) => hit(
    endpoint,
    body,
    {
      ...(data && { Authorization: data.accessToken }),
      ...headers,
    },
  );
};

export default useHit;
